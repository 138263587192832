import React from 'react'
import { MdDashboard, MdOutlineBrandingWatermark, MdOutlineCategory } from "react-icons/md";
import { LiaProductHunt } from "react-icons/lia";
import { BiCategoryAlt } from 'react-icons/bi';
import { FaFirstOrderAlt, FaRegUser } from 'react-icons/fa';

const Dashboard = React.lazy(() => import("../pages/dashboard/Dashboard"));
const Category = React.lazy(() => import("../pages/category/Category"));
const Brand = React.lazy(() => import("../pages/brand/Brand"));
const SubCategory = React.lazy(() => import("../pages/subCategory/SubCategory"));
const Product = React.lazy(() => import("../pages/product/Product"));
const User = React.lazy(() => import("../pages/user/User"));
const Order = React.lazy(() => import("../pages/order/Order"));

export const superAdminRoutes = [
    {
        path: "/dashboard/data",
        element: <Dashboard />,
        name: "Dashboard",
        icon: <MdDashboard size={"1.5em"} />,
        sideBarVisible: true,
    },
    {
        path: "/dashboard/category",
        element: <Category />,
        name: "Category",
        icon: <BiCategoryAlt size={"1.5em"} />,
        sideBarVisible: true,
    },
    {
        path: "/dashboard/band",
        element: <Brand />,
        name: "Brand",
        icon: <BiCategoryAlt size={"1.5em"} />,
        sideBarVisible: true,
    },
    {
        path: "/dashboard/sub-category",
        element: <SubCategory />,
        name: "Sub Category",
        icon: <BiCategoryAlt size={"1.5em"} />,
        sideBarVisible: true,
    },
    {
        path: "/dashboard/product",
        element: <Product />,
        name: "Product",
        icon: <LiaProductHunt size={"1.5em"} />,
        sideBarVisible: true,
    },
    {
        path: "/dashboard/user",
        element: <User />,
        name: "User",
        icon: <FaRegUser size={"1.5em"} />,
        sideBarVisible: true,
    },
    {
        path: "/dashboard/order",
        element: <Order />,
        name: "Order",
        icon: <FaFirstOrderAlt size={"1.5em"} />,
        sideBarVisible: true,
    }
];

export const adminRoutes = [
    {
        path: "/dashboard/data",
        element: <Dashboard />,
        name: "Dashboard",
        icon: <MdDashboard size={"1.5em"} />,
        sideBarVisible: true,
    },
    {
        path: "/dashboard/category",
        element: <Category />,
        name: "Category",
        icon: <BiCategoryAlt size={"1.5em"} />,
        sideBarVisible: true,
    },
    {
        path: "/dashboard/sub-category",
        element: <SubCategory />,
        name: "Sub Category",
        icon: <MdOutlineCategory size={"1.5em"} />,
        sideBarVisible: true,
    },
    {
        path: "/dashboard/band",
        element: <Brand />,
        name: "Brand",
        icon: <MdOutlineBrandingWatermark size={"1.5em"} />,
        sideBarVisible: true,
    },
    {
        path: "/dashboard/product",
        element: <Product />,
        name: "Product",
        icon: <LiaProductHunt size={"1.5em"} />,
        sideBarVisible: true,
    },
    {
        path: "/dashboard/user",
        element: <User />,
        name: "User",
        icon: <FaRegUser size={"1.5em"} />,
        sideBarVisible: true,
    }
];

export const deliveryRoutes = [
    {
        path: "/dashboard/data",
        element: <Dashboard />,
        name: "Dashboard",
        icon: <MdDashboard size={"1.5em"} />,
        sideBarVisible: true,
    }
];

export const storeKeeperRoutes = [
    {
        path: "/dashboard/data",
        element: <Dashboard />,
        name: "Dashboard",
        icon: <MdDashboard size={"1.5em"} />,
        sideBarVisible: true,
    }
];
