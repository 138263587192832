import { Box, Button, Center, Divider, Heading, Modal, ModalCloseButton, ModalContent, ModalOverlay, Progress, Stack, useToast } from '@chakra-ui/react'
import { Field, Form, Formik } from 'formik'
import React from 'react'
import * as Yup from "yup";
import { useAuth } from '../../../context/AuthContext';
import { useState } from 'react';
import axios from 'axios';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import CustomInput from '../../../components/form/CustomInput';
import ImageUpload from '../../../components/upload/ImageUpload';
import ReactQuill from 'react-quill';
import { ClipLoader } from 'react-spinners';

const ProfileChangeModal = ({ isOpen, onClose }) => {
  const queryClient = useQueryClient();
  const { token } = useAuth();
  const [uploadPercentage, setuploadPercentage] = useState(null);

  const toast = useToast();

  let formData = new FormData();

  const headers = {
    "Content-Type": "application/json",
    Accept: "application/json",
    Authorization: `Bearer ${token}`,
  };

  const myBasicAccount = useQuery(
    [`myAccount`, token],
    async () =>
      await axios.get(`${process.env.REACT_APP_BACKEND_URL}adminAuth/me`, {
        headers,
      }),
    {
      keepPreviousData: true,
      refetchOnWindowFocus: false,
      retry: false,
      enabled: !!token && !!isOpen,
    }
  );

  const updateProfile = useMutation(
    async ({ formData }) =>
      await axios.patch(
        `${process.env.REACT_APP_BACKEND_URL}adminAuth/me/update`,
        formData, {
        headers,
        onUploadProgress: (progressEvent) => {
          const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
          setuploadPercentage(percentCompleted);
        },
      }
      ),
    {
      retry: false,
    }
  );
  const initialValues = {
    name: myBasicAccount?.data?.data?.data?.name,
    email: myBasicAccount?.data?.data?.data?.email,
    // switch phone to integer triming spaces and removing +
    phone: parseInt(myBasicAccount?.data?.data?.data?.phone?.replace(/\s/g, '')?.replace('+', '')),
    avatar: myBasicAccount?.data?.data?.data?.avatar
  };

  const validationSchema = Yup.object({
    name: Yup.string().required("Required"),
    email: Yup.string().email("Invalid email format").required("Required"),
    phone: Yup.string().required("Required"),
    avatar: Yup.mixed(),
  });

  const onSubmit = async (values) => {
    const { name, email, phone, avatar } = values;
    formData.append("name", name);
    formData.append("email", email);
    formData.append("phone", phone);
    formData.append("avatar", avatar);


    try {
      updateProfile.mutate(
        {
          formData,
        },
        {
          onSuccess: (responseData) => {
            queryClient.invalidateQueries(["myBasicAccount"]);
            queryClient.invalidateQueries(["myAccount"]);
            toast({
              title: "Profile updated successfully",
              status: "success",
              duration: 3000,
              isClosable: true,
            });
            onClose();
            setuploadPercentage(null);
          },
          onError: (responseDataError) => {
            setuploadPercentage(null);

            toast({
              title:
                responseDataError?.response?.data?.data ||
                responseDataError?.response?.data?.message ||
                "Profile not updated",
              status: "error",
              duration: 3000,
              isClosable: true,
            });
          },
        }
      );
      // Redirect the admin to a different page
    } catch (err) { }
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="2xl">
      <ModalOverlay />

      <ModalContent>
        <ModalCloseButton />
        {
          !myBasicAccount.isFetching ?
            <Formik
              initialValues={initialValues}
              validationSchema={validationSchema}
              onSubmit={onSubmit}
            >
              {(formik) => {
                return (
                  <Form>
                    <Stack px={{ base: "3%", sm: "5%", md: "10%" }} mb={10}>
                      <Stack my={4} spacing="15px">
                        <Heading
                          color="#22BBE6"
                          fontSize={{ base: "1.5rem", md: "2rem" }}
                          fontWeight="bold"
                        >
                          Update Profile
                        </Heading>
                        <Divider color="#22BBE6" w="100%" />
                      </Stack>
                      <Stack
                        spacing={{ base: "20px", md: "20px" }}
                      >
                        <Field name='name'>
                          {({ field, form }) => (
                            <CustomInput
                              field={field}
                              form={form}
                              title={"Name"}
                              htmlFor='name'
                              error={formik.errors.name}
                              touched={formik.touched.name}
                            />
                          )}
                        </Field>
                        <Field name='email'>
                          {({ field, form }) => (
                            <CustomInput
                              field={field}
                              form={form}
                              title={"Email"}
                              htmlFor={"email"}
                              error={formik.errors.email}
                              touched={formik.touched.email}
                              extra={"email"}
                            />
                          )}
                        </Field>
                        <Field name='phone'>
                          {({ field, form }) => (
                            <CustomInput
                              field={field}
                              form={form}
                              title={"Phone"}
                              htmlFor={"phone"}
                              error={formik.errors.phone}
                              touched={formik.touched.phone}
                              extra={"phone"}
                            />
                          )}
                        </Field>


                        <Field name="avatar">
                          {({ field, form }) => (
                            <ImageUpload
                              field={field}
                              form={form}
                              formData={formData}
                              htmlFor="avatar"
                              prevPic={formik.initialValues.avatar}
                            />
                          )}
                        </Field>
                        <Stack spacing={"20px"}>
                          <Box>
                            <Button
                              type="submit"
                              bg="#22BBE6"
                              color="white"
                              border="1px solid #22BBE6"
                              _hover={{
                                bg: "white",
                                color: "#22BBE6",
                              }}
                              disabled={
                                updateProfile.isLoading ||
                                !(formik?.isValid && formik?.dirty)
                              }
                              isLoading={updateProfile.isLoading}

                            >
                              Submit
                            </Button>
                          </Box>
                          <Box maxW={{ base: "full", md: "88%" }}
                            display={updateProfile.isLoading ? "block" : "none"}>
                            <Progress
                              colorScheme='blue'
                              size='md'
                              value={uploadPercentage}
                              hasStripe={true}
                              isAnimated={true}
                            />
                          </Box>
                        </Stack>


                      </Stack>
                    </Stack>


                  </Form>
                );
              }}
            </Formik>
            :
            <Center minH='70vh'>
              <ClipLoader size={45} color='#22BBE6' />
            </Center>
        }
      </ModalContent>
    </Modal>
  )
}

export default ProfileChangeModal