import React from 'react'
import { Navigate, Route, Routes } from 'react-router-dom'
import { superAdminRoutes } from './Data'
import AdminNavigation from '../components/navigation/AdminNavigation'

const SuperAdminRoutes = () => {
    return (
        <Routes>
            <Route element={<AdminNavigation />}>
                {superAdminRoutes.map(({ path, element }, index) => (
                    <Route key={index} path={path} element={element} />
                ))}
                <Route path="*" element={<Navigate to="/dashboard/data" />} />
            </Route>
        </Routes>
    )
}

export default SuperAdminRoutes