import { Box, Button, Img, Stack, Text } from "@chakra-ui/react";
import React, { useRef, useState, useEffect } from "react";

import "./ImageUpload.css";
import { AiFillFile, AiFillVideoCamera } from "react-icons/ai";

const ImageUpload = (props) => {
  const { form, field, prevPic, htmlFor, fileSize, fileType } = props;
  const [file, setFile] = useState();
  const [count, setCount] = useState(0);

  const [previewUrl, setPreviewUrl] = useState(prevPic);
  const [isValid, setIsValid] = useState(false);
  const isPdf = fileType === "pdf" && htmlFor === "file";
  const isFile = htmlFor === "file" && fileType !== "pdf";
  const isVideo = htmlFor === "video";
  const isImage = htmlFor === "image" || htmlFor === "thumbnail" || htmlFor === "avatar";

  const filePickerRef = useRef();

  useEffect(() => {
    if (!file) {
      return;
    }
    const fileReader = new FileReader();
    // setFile(fileReader);
    form?.setFieldValue("", fileReader);
    fileReader.onload = () => {
      if (isPdf || isFile || isVideo) {
        setPreviewUrl("file")
      } else {
        setPreviewUrl(fileReader.result);
      }
    };
    fileReader.readAsDataURL(file);
  }, [file]);

  function formatBytes(bytes, decimals = 2) {
    if (bytes === 0) return "0 Bytes";

    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

    const i = Math.floor(Math.log(bytes) / Math.log(k));

    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
  }

  const pickedHandler = (event) => {
    let pickedFile;
    let fileIsValid = isValid;
    if (event.target.files && event.target.files.length === 1) {
      pickedFile = event.target.files[0];
      form.setFieldValue(htmlFor, event.target.files[0]);
      setFile(pickedFile);

      setIsValid(true);
      fileIsValid = true;
      setCount(1);
    } else {
      setIsValid(false);
      fileIsValid = false;
    }
    // props.onInput(props.id, pickedFile, fileIsValid);
  };

  const pickImageHandler = () => {
    filePickerRef.current.click();
  };

  return (
    <div className="form-control">
      <input
        id={props.id}
        ref={filePickerRef}
        style={{ display: "none" }}
        type="file"
        accept={isPdf ?
          ".pdf" :
          isFile ?
            ".psd, .indd, .ai, .docx, .doc, .idml, .eps, .pdf" :
            isVideo ? ".mp4,.avi,.mov,.wmv,.flv,.mkv,.webm" :
              isImage ? ".jpg,.png,.jpeg,.webp,.avif" : ""
        }
        onChange={pickedHandler}
      />
      <div className={`image-upload ${props.center && "center"}`}>
        <Stack direction="row" spacing="25px" align="center">
          <div className={`${isFile || isPdf || isVideo ? "file" : "image"}-upload__preview`}>
            {previewUrl && count === 0 && (
              <>
                <Stack>
                  <Box cursor="pointer">
                    {
                      isFile || isPdf ?
                        <AiFillFile
                          size={38}
                          color="#22BBE6"
                          onClick={() => window.open(`${process.env.REACT_APP_FILE_URL}${prevPic}`, "_blank")}
                        />
                        : isVideo ?
                          <AiFillVideoCamera
                            size={38}
                            color="#22BBE6"
                            onClick={() => window.open(`${process.env.REACT_APP_FILE_URL}${prevPic}`, "_blank")}
                          />
                          :
                          <Img
                            src={`${process.env.REACT_APP_FILE_URL}${previewUrl}`}
                            maxH={"12rem"}
                            //   rounded="full"
                            alt="Preview"
                          />
                    }
                  </Box>
                  <Text fontSize="14px">
                    {fileSize}
                  </Text>
                </Stack>
              </>
            )}
            {previewUrl && count > 0 && (
              <Stack minH={isFile || isPdf ? "70px" : isVideo ? "70px" : "180px"}>
                {
                  isFile || isPdf ?
                    <AiFillFile
                      size={38}
                      color="#22BBE6"
                    /> :
                    isVideo ?
                      <AiFillVideoCamera
                        size={38}
                        color="#22BBE6"
                      /> :
                      <Img maxH="180px" src={previewUrl} alt="Preview" />
                }
                <Text> {formatBytes(file?.size)} </Text>
              </Stack>
            )}
            {!previewUrl && (
              isPdf ? <p>{"Attach a PDF."}</p> :
                isFile ? <p>{"Attach a file."}</p> :
                  isVideo ? <p>{"Attach a video."}</p> :
                    isImage ? <p>{"Please pick an image."}</p> :
                      null
            )}
          </div>
          <Button type="button" onClick={pickImageHandler}>
            {isPdf ? "Pick PDF" : isFile ? "Pick File" : isVideo ? "Pick Video" : "Pick Image"}
          </Button>
        </Stack>
      </div>
      {!isValid && <p>{props.errorText}</p>}
    </div>
  );
};

export default ImageUpload;
