import { Box, Img } from "@chakra-ui/react";
import React from "react";
import logo from "../assets/logo.jpg"

export const Logo = ({ color }) => {
    return (
        <Box>
            <Img
                maxH={{ base: "50px", sm: "70px" }}
                minH={{ base: "50px", md: "70px" }}
                src={logo}
            />
        </Box>
    );
};
